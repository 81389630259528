import React from 'react'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Button from '../components/Button'

import { graphql } from "gatsby"
import Picture from '../components/Picture'

const ColorsnGlazes = ({ data }) => {
	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const pageData = data.allPrismicColorsAndGlazesPage.edges[0].node.data

    const colors = pageData.colors
    const standardGlazes = pageData.standard_glazes
    const artGlazes = pageData.art_glazes

    const pageTitle = "ALPHATON Terracotta Colors and Glazes | Shildan Group";
    const metaDescription = "Natural terracotta ALPHATON panels can be designed in nearly any terracotta color and are also available in custom terracotta glazes.";
    const metaKeywords = "Terracotta rainscreen, teracotta colors, terracotta glaze";

return (
    <div>
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
      <Header currentPage="colorsnglazes" navigation={prismicNavigation} footer={prismicFooter} />

      <section className="ColorsnGlazesContainer">

        <h1 className="ColorsnGlazes__Title"
          data-is-on-screen>
           {pageData.heading[0]?.text}
        </h1>

        <div className='ColorsnGlazes__Desc'>
        {pageData.heading_desc[0]?.text}
        </div>

        <div className='ColorsnGlazes__Section'>
          <div className='ColorsnGlazes__Section_TextLeft'>
              {pageData.colors_textleft[0]?.text}
          </div>
          <div className='ColorsnGlazes__Section_TextRight'>
              {pageData.colors_textright[0]?.text}
            <div>
              <Button modifier="normal"
                className="ColorsGlazes__Button"
                link={'/get_in_touch'}
              >    Contact Us
              </Button>
            </div>
          </div>
          <div className='ColorsnGlazes__Section_Tiles'>
            <ul>
              {colors.map((image, index) => {
                return (
                  <li key={`img-${index}`}>
                      <Picture src={image.image.url} noDownload={true}/>
                      <br />
                      <span>{image.name[0]?.text}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <hr />
          { /* ART GLAZES SECTION */}
        <div className='ColorsnGlazes__Section'>
          <div className='ColorsnGlazes__Section_TextLeft'>
              {pageData.art_glazes_textleft[0]?.text}
          </div>
          <div className='ColorsnGlazes__Section_TextRight'>
            <div className='ColorsnGlazes__Section_TextRightHeading'>Art Glazes </div>
              {pageData.art_glazes_textright[0]?.text}
            </div>
          <div className='ColorsnGlazes__Section_Tiles'>
            <ul>
              {artGlazes.map((image, index) => {
                return (
                  <li key={`img-${index}`}>
                    <Picture src={image.image.url} noDownload={true}/>
                    <br />
                    <span>{image.name[0]?.text}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        { /* STANDARD GLAZES SECTION */}
        <div className='ColorsnGlazes__Section'>
          <div className={pageData.standard_glazes_textleft[0].length > 0 ? `ColorsnGlazes__Section` : `ColorsnGlazes__Section ColorsnGlazes__Section--empty`}>
              {pageData.standard_glazes_textleft[0]?.text}
          </div>
          <div className={pageData.standard_glazes_textleft[0].length > 0 ? `ColorsnGlazes__Section_TextRight` : `ColorsnGlazes__Section_TextRight NoPartner`}>
            <div className='ColorsnGlazes__Section_TextRightHeading'>Standard Glazes </div>           
            {pageData.standard_glazes_textright[0]?.text}
            <div>
              <Button modifier="normal"
                className="ColorsGlazes__Button"
                link={'/get_in_touch'}
              >    Contact Us
              </Button>
            </div>
          </div>
          <div className='ColorsnGlazes__Section_Tiles'>
            <ul>
              {standardGlazes.map((image, index) => {
                return (
                  <li key={`img-${index}`}>
                    <Picture src={image.image.url} noDownload={true}/>
                    <br />
                    <span>{image.name[0]?.text}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        <br />
      </section>


      <Footer footer={prismicFooter.data} hasBorder={false} showImage={false} />
    </div>
  )
}


export const query = graphql`
	query AlphatonColorsGlazes {
    allPrismicColorsAndGlazesPage(filter: {uid: {eq: "alphatoncolorsandglazes"}}) {
      edges {
        node {
          id
          data {
            heading {
              text
            }
            heading_desc {
              text
            }
            colors_textleft {
              text
            }
            colors_textright {
              text
            }
            art_glazes_textleft {
              text
            }
            art_glazes_textright {
              text
            }
            standard_glazes_textleft {
              text
            }
            standard_glazes_textright {
              text
            }
            colors {
              image {
                url
              }
              name {
                text
              }
            }
            standard_glazes {
              image {
                url
              }
              name {
                text
              }
            }
            art_glazes {
              image {
                url
              }
              name {
                text
              }
            }
          }
        }
      }
    }        
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
	}
`


export default ColorsnGlazes
